import { useState } from 'react';
import './App.css';
import axios from 'axios';

function App() {

  const [fields, setFields] = useState({
    name: '',
    phone: '',
  });
  const [result, setResult] = useState(null);

  const sendForm = (event) => {
    event.preventDefault();
    axios
      .post('/send', { ...fields })
      .then(response => {
        console.log();
        setResult(response.data);
        setFields({ name: '', phone: ''});
      })
      .catch(() => {
        setResult({ success: false, message: 'Something went wrong. Try again later'});
      })
  };

  const onInputChange = event => {
    const { name, value } = event.target;

    setFields({
      ...fields,
      [name]: value
    });
  };

  return (
    <div className="App">
      <form onSubmit={sendForm}>
        <h1>Залиште заявку</h1>
        <input type="text" name="name" placeholder="Ваше імя" value={fields.name} onChange={onInputChange} />
        <input type="tel" name="phone" placeholder="+38 (___) ___ __ __" value={fields.phone} onChange={onInputChange} />
        <button type="submit">Відправити заявку</button>
        {result && (
          <p className={`${result.success ? 'success' : 'error'}`}>
            {result.message}
          </p>
        )}
      </form>
    </div>
  );
}

export default App;
